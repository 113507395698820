import React from 'react';
import styleForm from '../styles/forms.module.scss'

const LayoutForm = (props) => {
    return (
        <article className={styleForm.articleFormContainer}>
            <header>
                {props.header}
            </header>
            <main>
                {props.children}
            </main>
            <footer>
                {props.footer}
            </footer>
        </article>
    );
}

export default LayoutForm;
