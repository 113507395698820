import React from "react"
import { Form, Row, Col, Container, Button } from "react-bootstrap"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { graphql, StaticQuery, navigate, Link } from "gatsby"
import LayoutForm from "./layout-form"
import StyleModule from "../styles/forms.module.scss"
import StyleClients from "../styles/clients.module.scss"
import StyleCRM from "../styles/crm.module.scss"
import { Component } from "react"
import BtnStyle from "../styles/button.module.scss"
import axios from "axios"
import group1 from "../images/Group-1.png"
import group2 from "../images/Group-2.png"
import group3 from "../images/Group-3.png"
import group4 from "../images/Group-4.png"
import gPlay from "../images/gplay.png"
import appStore from "../images/appStore.png"
import Switch from "react-switch";
import SEO from "../components/seo"

export default class FormStart extends Component {
    state = {
        step: "one",
        firstName: "",
        lastName: "",
        corporateEmail: "",
        phoneNumber: "",
        websiteLink: "",
        person_id: "",
        objective: '',
        target: '',
        salesNavigator: false,
        blog: false,
        crm: "",
        title: ''
    }
    constructor(props) {
        super(props)
    }

    handleFirsNameChange = event => {
        this.setState({
            firstName: event.target.value,
        })
    }
    handlelastNameChange = event => {
        this.setState({
            lastName: event.target.value,
        })
    }
    handlecorporateEmail = event => {
        this.setState({
            corporateEmail: event.target.value,
        })
    }
    handlephoneNumber = event => {
        this.setState({
            phoneNumber: event.target.value,
        })
    }
    handlecompanyWebsiteLink = event => {
        this.setState({
            websiteLink: event.target.value,
        })
    }

    handleObjectiveChange = (event) => {
        this.setState({
            objective: event.target.value,
            title: event.target.value
        })
    }
    handleTargetChange = (event) => {
        this.setState({
            target: event.target.value
        })
    }

    handleChangeSales = checked => {
        this.setState({ salesNavigator: checked });
        if (checked) {
            this.setState({ salesNavigator: true })
        } else {
            this.setState({ salesNavigator: false })
        }
    }

    handleChangeBlog = checked => {
        this.setState({ blog: checked });
        if (checked) {
            this.setState({ blog: true })
        } else {
            this.setState({ blog: false })
        }
    }

    handleCrmChange = event => {
        this.setState({
            crm: event.target.value,
        })
    }

    handleSubmitStepOne = event => {
        event.preventDefault()

        const { firstName, lastName, corporateEmail, phoneNumber, websiteLink } = this.state

        axios
            .post(
                `https://api.pipedrive.com/v1/persons?api_token=46a6628329e04449e8568861dccf32c95fec0775`,
                {
                    name: [firstName, lastName].join(" "),
                    email: [corporateEmail],
                    phone: [phoneNumber],
                    visible_to: 3
                }
            )
            .then(
                ({
                    data: {
                        data: { id },
                    },
                }) => {
                    if (id) {
                        this.setState({ id })
                        this.setState({ step: "two", person_id: id })
                    }
                }
            )
            .catch(err => {
                console.log("err", err)
            })
    }

    handleSubmitStepTwo = event => {
        event.preventDefault();
        this.setState({
            step: "three"
        })
    }

    skipHandlerForm = event => {
        event.preventDefault();
        this.setState({
            step: "one"
        })
        navigate(`/`)
    }
    handleGoSecond = event => {
        event.preventDefault();
        this.setState({
            step: "two"
        })
    }

    handleSubmitThree = event => {
        event.preventDefault()
        const {
            title,
            person_id,
            websiteLink,
            objective,
            target,
            salesNavigator,
            blog,
            crm
        } = this.state;

        console.log(this.state)

        axios.post(`https://api.pipedrive.com/v1/deals?api_token=46a6628329e04449e8568861dccf32c95fec0775`, {
            title: title || '(empty)',
            person_id: person_id,
            user_id: 12027727,
            stage_id: 9,
            '60803c8f65a7c543db53f750f8ede13de48bf86f': websiteLink,
            '47c6b22f2aa86391edc9c01c4c745b604dae429d': objective,
            'da310ac643fd799be0233522088c29599ab65963': target,
            'bb112bcb8274229b45ffa651a5652f840578ee7a': salesNavigator ? 'Yes' : 'No',
            'c1c9c3fd96050627c5cbb796776222a07bab4925': blog ? 'Yes' : 'No',
            '7d98381a532b6f2c782a83786142ee5eb96bec72': crm
        })
            .then(function (response) {
                console.log(response);
            })
            .catch((err) => {
                console.log('err', err);
            })

        this.setState({
            step: "four"
        })
        console.log('state to 4', this.state)
    }

    renderStepOne = data => (
        <Container fluid>
            <Row>
                <Form
                    className={StyleModule.containerLayoutForm}
                    onSubmit={this.handleSubmitStepOne}>
                    <Col lg={6} className={StyleModule.firstBlock}>
                        {data.allContentfulFormGetStarted.edges.map(item => {
                            return (
                                <article key={item.node.id}>
                                    <header>
                                        <Col lg={12} mf={12} sm={12}>
                                            <div className="d-none d-lg-block d-md-block d-xl-block">
                                                <h1 className={StyleModule.h1}>{item.node.title}</h1>
                                                {documentToReactComponents(item.node.subtitle.json)}
                                            </div>
                                            <div className={item.containerTimeline}>
                                                <img src={group1} alt="" className="img-fluid mt-4" />
                                            </div>
                                            <div className="mt-5 d-sm-block d-block d-lg-none d-md-none d-xl-none">
                                                <h1 className={StyleModule.h1}>{item.node.title}</h1>
                                                {documentToReactComponents(item.node.subtitle.json)}
                                            </div>
                                        </Col>
                                    </header>
                                    <main>
                                        <Col lg={12}>
                                            <Row className="mt-3">
                                                <Col sm={12} md={6} lg={6} xl={6}>
                                                    <Form.Group controlId="firstName">
                                                        <Form.Label>{item.node.labelFirstName}</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={this.state.firstName}
                                                            onChange={this.handleFirsNameChange}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col sm={12} md={6} lg={6} xl={6}>
                                                    <Form.Group controlId="lastName">
                                                        <Form.Label>{item.node.labelLastName}</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={this.state.lastName}
                                                            onChange={this.handlelastNameChange}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={12}>
                                            <Row>
                                                <Col sm={12} md={6} lg={6} xl={6}>
                                                    <Form.Group controlId="corporateEmail">
                                                        <Form.Label>{item.node.labelEmail}</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={this.state.corporateEmail}
                                                            onChange={this.handlecorporateEmail}
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col sm={12} md={6} lg={6} xl={6}>
                                                    <Form.Group controlId="phoneNumber">
                                                        <Form.Label>{item.node.labelNumber}</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={this.state.phoneNumber}
                                                            onChange={this.handlephoneNumber}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={12}>
                                            <Form.Group controlId="websiteLink">
                                                <Form.Label>{item.node.companyWebsiteLink}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={this.state.companyWebsiteLink}
                                                    onChange={this.handlecompanyWebsiteLink}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className="d-flex justify-content-between mt-5">
                                            <Button className={BtnStyle.btnStent} type="submit">
                                                {item.node.ctaText}
                                            </Button>
                                            <a className={`${BtnStyle.btnText} d-none d-lg-block d-md-block d-xl-block`} onClick={this.skipHandlerForm}>
                                                {item.node.ctaBack}
                                            </a>
                                        </Col>
                                    </main>
                                </article>
                            )
                        })}
                    </Col>

                    <Col lg={6} className={`${StyleModule.secondBlock} d-none d-lg-block d-md-block`}>
                        <article>
                            {data.allContentfulFormGetStarted.edges.map(item => {
                                return (
                                    <header key={item.node.id}>
                                        <img
                                            src={item?.node?.logo?.file?.url ? item.node.logo.file.url : ''}
                                            alt={item?.node?.logo?.file?.description ? item.node.logo.file.description : ''}
                                            className="img-fluid"
                                            style={{
                                                maxWidth: "112px",
                                                marginBottom: "120px",
                                            }}
                                        />
                                    </header>
                                )
                            })}

                            {data.allContentfulClientsSay.edges.map(client => {
                                return (
                                    <main key={client.node.id}>
                                        <Row>
                                            <Col lg={12}>
                                                <img
                                                    src={client?.node?.image?.file?.url ? client.node.image.file.url : ''}
                                                    alt={client?.node?.clientName ? client.node.clientName : ''}
                                                    className="img-fluid mr-auto d-block"
                                                />
                                            </Col>
                                            <Col lg={11} style={{ marginTop: "40px" }}>
                                                <div className={StyleModule.clients}>
                                                    {documentToReactComponents(
                                                        client.node.clientComment.json
                                                    )}
                                                </div>
                                                <div className={StyleClients.tooltip}>
                                                    <div className={StyleClients.pConatiner}>
                                                        <p>{client?.node?.clientName ? client.node.clientName : ''}</p>
                                                        <p>{client?.node?.clientWork ? client.node.clientWork : ''}</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </main>
                                )
                            })}
                            <footer>
                                <Row>
                                    <Col lg={12} md={12} sm={12}>
                                        <p className={StyleCRM.headdingWhite}>
                                            {data.contentfulCrmToolsWhite.title}
                                        </p>
                                    </Col>
                                    {data.contentfulCrmToolsWhite.logos.map(brand => {
                                        return (
                                            <Col key={brand.id}>
                                                <img
                                                    src={brand?.file?.url ? brand.file.url : ''}
                                                    alt={brand?.title ? brand.title : ''}
                                                    className={StyleCRM.imageStyle}
                                                />
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </footer>
                        </article>
                    </Col>
                </Form>
            </Row>
        </Container>
    )


    renderStepTwo = data => (
        <Container fluid>
            <Row>
                <Form
                    className={StyleModule.containerLayoutForm}
                    onSubmit={this.handleSubmitStepTwo}>
                    <Col lg={6} className={StyleModule.firstBlock}>
                        <article>
                            <header>
                                <div className="d-none d-lg-block d-md-block d-xl-block">
                                    <h1 className={StyleModule.h1}>
                                        {data.contentfulFormSecondStep.title}
                                    </h1>
                                    {documentToReactComponents(
                                        data.contentfulFormSecondStep.subtitle.json
                                    )}
                                </div>
                                <img src={group2} alt="" className="img-fluid mt-4" />
                                <div className="mt-5 d-sm-block d-lg-none d-md-none d-xl-none">
                                    <h1 className={StyleModule.h1}>
                                        {data.contentfulFormSecondStep.title}
                                    </h1>
                                    {documentToReactComponents(
                                        data.contentfulFormSecondStep.subtitle.json
                                    )}
                                </div>
                            </header>
                            <main className="mt-3">
                                <Form.Group controlId="currentObjective">
                                    <Form.Label>
                                        {data.contentfulFormSecondStep.labelObjective}
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={this.state.objective}
                                        onChange={this.handleObjectiveChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="currentTarget">
                                    <Form.Label>
                                        {data.contentfulFormSecondStep.labelTarget}
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={this.state.target}
                                        onChange={this.handleTargetChange}
                                    />
                                </Form.Group>

                                <Col className={`${BtnStyle.containerBtnSmall} d-flex justify-content-between mt-5`}>
                                    <a className={BtnStyle.btnText} onClick={this.skipHandlerForm}>
                                        {data.contentfulFormSecondStep.ctaSkipText}
                                    </a>
                                    <Button className={BtnStyle.btnStent} type="submit">
                                        {data.contentfulFormSecondStep.ctaText}
                                    </Button>
                                </Col>
                            </main>
                        </article>
                    </Col>

                    <Col lg={6} className={`${StyleModule.secondBlock} d-none d-lg-block d-md-block`}>
                        <article>
                            {data.allContentfulFormGetStarted.edges.map(item => {
                                return (
                                    <header key={item.node.id}>
                                        <img
                                            src={item.node.logo.file.url}
                                            alt={item.node.logo.description}
                                            className="img-fluid"
                                            style={{ maxWidth: "112px", marginBottom: "120px" }}
                                        />
                                    </header>
                                )
                            })}
                            <main>
                                <Col lg={12}>
                                    <p className={StyleModule.ckickTitle}>
                                        {data.contentfulFormSecondStep.quickTipTitle}
                                    </p>
                                    <div className={StyleModule.tips}>
                                        {documentToReactComponents(
                                            data.contentfulFormSecondStep.quickTipDescription.json
                                        )}
                                    </div>
                                </Col>
                            </main>
                            <footer>
                                <Row>
                                    <Col lg={12} md={12} sm={12}>
                                        <p className={StyleCRM.headdingWhite}>
                                            {data.contentfulCrmToolsWhite.title}
                                        </p>
                                    </Col>
                                    {data.contentfulCrmToolsWhite.logos.map(brand => {
                                        return (
                                            <Col key={brand.id}>
                                                <img
                                                    src={brand.file.url}
                                                    alt={brand.title}
                                                    className={StyleCRM.imageStyle}
                                                />
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </footer>
                        </article>
                    </Col>
                </Form>
            </Row>
        </Container>
    )

    renderStepThree = data => (
        <Container fluid>
            <Row>
                <Form
                    className={StyleModule.containerLayoutForm}
                    onSubmit={this.handleSubmitThree}>
                    <Col lg={6} className={StyleModule.firstBlock}>
                        <article>
                            <header>
                                <div className="d-none d-lg-block d-md-block d-xl-block">
                                    <h1 className={StyleModule.h1}>
                                        {data.contentfulFormThirdStep.title}
                                    </h1>
                                    {documentToReactComponents(
                                        data.contentfulFormThirdStep.subtitle.json
                                    )}
                                </div>
                                <img src={group3} alt="" className="img-fluid mt-3" />
                                <div className="mt-5 d-sm-block d-lg-none d-md-none d-xl-none">
                                    <h1 className={StyleModule.h1}>
                                        {data.contentfulFormThirdStep.title}
                                    </h1>
                                    {documentToReactComponents(
                                        data.contentfulFormThirdStep.subtitle.json
                                    )}
                                </div>
                            </header>
                            <main className="mt-3">
                                <Form.Group
                                    controlId="salesNavigator"
                                    className="d-flex flex-row justify-content-between"
                                >
                                    <Form.Label>{data.contentfulFormThirdStep.labelVersion}</Form.Label>
                                    <Switch id="salesNavigator" onChange={this.handleChangeSales} checked={this.state.salesNavigator} onColor="#702eea" offColor="#ccc" />
                                </Form.Group>

                                <Form.Group
                                    controlId="salesNavigator"
                                    className="d-flex flex-row justify-content-between"
                                >
                                    <Form.Label>{data.contentfulFormThirdStep.labelWebsite}</Form.Label>
                                    <Switch id="blog" onChange={this.handleChangeBlog} checked={this.state.blog} onColor="#702eea" offColor="#ccc" />
                                </Form.Group>


                                <Form.Group controlId="crm">
                                    <Form.Label>
                                        {data.contentfulFormThirdStep.labelCrm}
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={this.state.crm}
                                        onChange={this.handleCrmChange}
                                    />
                                </Form.Group>

                                <Col className={`${BtnStyle.containerBtnSmall} d-flex justify-content-between mt-5`}>
                                    <a className={BtnStyle.btnText} onClick={this.handleGoSecond}>
                                        {data.contentfulFormThirdStep.ctaSkipText}
                                    </a>
                                    <Button className={BtnStyle.btnStent} type="submit">
                                        {data.contentfulFormThirdStep.ctaText}
                                    </Button>
                                </Col>
                            </main>
                        </article>
                    </Col>

                    <Col lg={6} className={`${StyleModule.secondBlock} d-none d-lg-block d-md-block`}>
                        <article>
                            {data.allContentfulFormGetStarted.edges.map(item => {
                                return (
                                    <header key={item.node.id}>
                                        <img
                                            key={item.node.id}
                                            src={item.node.logo.file.url}
                                            alt={item.node.logo.description}
                                            className="img-fluid"
                                            style={{
                                                maxWidth: "112px",
                                                marginBottom: "120px",
                                            }}
                                        />
                                    </header>
                                )
                            })}
                            <main>
                                <Col lg={12}>
                                    <p className={StyleModule.ckickTitle}>
                                        {data.contentfulFormThirdStep.quickTipTitle}
                                    </p>
                                    <div className={StyleModule.tips}>
                                        {documentToReactComponents(
                                            data.contentfulFormThirdStep.quickTipDescription
                                                .json
                                        )}
                                    </div>
                                </Col>
                            </main>
                            <footer>
                                <Row>
                                    <Col lg={12} md={12} sm={12}>
                                        <p className={StyleCRM.headdingWhite}>
                                            {data.contentfulCrmToolsWhite.title}
                                        </p>
                                    </Col>
                                    {data.contentfulCrmToolsWhite.logos.map(brand => {
                                        return (
                                            <Col key={brand.id}>
                                                <img
                                                    src={brand.file.url}
                                                    alt={brand.title}
                                                    className={StyleCRM.imageStyle}
                                                />
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </footer>
                        </article>
                    </Col>
                </Form>
            </Row>
        </Container>
    )

    renderStepFour = data => (
        <Container fluid>
            <Row>
                <Form className={StyleModule.containerLayoutForm}>
                    <Col lg={6} className={StyleModule.firstBlock}>
                        <article>
                            <header>
                                <div className="d-none d-lg-block d-md-block d-xl-block">
                                    <h1 className={StyleModule.h1}>{data.contentfulFormFourthStep.title}</h1>
                                    <p>{data.contentfulFormFourthStep.subtitle}</p>
                                </div>
                                <img src={group4} alt="" className="img-fluid mt-4" />
                                <div className="mt-5 d-sm-block d-lg-none d-md-none d-xl-none">
                                    <h1 className={StyleModule.h1}>{data.contentfulFormFourthStep.title}</h1>
                                    <p>{data.contentfulFormFourthStep.subtitle}</p>
                                </div>
                            </header>
                            <main>
                                <Col lg={12}>
                                    <div className={StyleModule.box}>
                                        {documentToReactComponents(data.contentfulFormFourthStep.boxOneText.json)}
                                        <a href="https://play.google.com/store/apps/details?id=io.stent.mobileapp" target="_blank" rel="noreferrer">
                                            <img src={gPlay} alt="" className="img-fluid" />
                                        </a>
                                        <a href="https://apps.apple.com/app/stent-io/id1441291275" target="_blank" rel="noreferrer">
                                            <img src={appStore} alt="" className="img-fluid" />
                                        </a>
                                    </div>

                                    <div className={StyleModule.box}>
                                        {documentToReactComponents(data.contentfulFormFourthStep.boxTwoText.json)}
                                        <a href={data.contentfulFormFourthStep.ctaLink} target="_blank" rel="noreferrer" className={BtnStyle.btnStentBlack}>
                                            {data.contentfulFormFourthStep.ctaText}
                                        </a>
                                    </div>
                                </Col>
                            </main>
                            <footer>
                                <a className={BtnStyle.btnText} href={data.contentfulFormFourthStep.backHomepageLink}>
                                    {data.contentfulFormFourthStep.backHomepage}
                                </a>
                            </footer>
                        </article>
                    </Col>

                    <Col lg={6} className={`${StyleModule.secondBlock} d-none d-lg-block d-md-block`}>
                        <article>
                            {data.allContentfulFormGetStarted.edges.map(item => {
                                return (
                                    <header key={item.node.id}>
                                        <img
                                            src={item.node.logo.file.url}
                                            alt={item.node.logo.description}
                                            className="img-fluid"
                                            style={{ maxWidth: "112px", marginBottom: "120px" }}
                                        />
                                    </header>
                                )
                            })}
                            <main>
                                <Col lg={12}>
                                    <p className={StyleModule.ckickTitle}>
                                        {data.contentfulFormFourthStep.quickTipTitle}
                                    </p>
                                    <div className={StyleModule.tips}>
                                        {documentToReactComponents(
                                            data.contentfulFormFourthStep.quickTipDescription.json
                                        )}
                                    </div>
                                </Col>
                            </main>
                            <footer>
                                <Row>
                                    <Col lg={12} md={12} sm={12}>
                                        <p className={StyleCRM.headdingWhite}>{data.contentfulCrmToolsWhite.title}</p>
                                    </Col>
                                    {data.contentfulCrmToolsWhite.logos.map(brand => {
                                        return (
                                            <Col key={brand.id}>
                                                <img
                                                    src={brand.file.url}
                                                    alt={brand.title}
                                                    className={StyleCRM.imageStyle}
                                                />
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </footer>
                        </article>
                    </Col>
                </Form>
            </Row>
        </Container>
    )

    renderStep = () => {
        switch (this.state.step) {
            case "one":
                return this.renderStepOne
            case "two":
                return this.renderStepTwo
            case "three":
                return this.renderStepThree
            case "four":
                return this.renderStepFour
            default:
                return null
        }
    }
    render() {
        return (
            <LayoutForm>
                <SEO title="Start your free trial" />
                <StaticQuery
                    // query={this.retreiveQuery()}
                    query={graphql`
            query {
              contentfulLogo {
                logoAlt
                logoLink
                logo {
                  file {
                    url
                  }
                }
              }
              allContentfulClientsSay(filter: {node_locale: {eq: "en-US"}}) {
                edges {
                  node {
                    clientSayBigTitle
                    clientName
                    clientWork
                    image {
                      file {
                        url
                      }
                    }
                    id
                    clientComment {
                      json
                    }
                  }
                }
              }
              contentfulCrmToolsWhite {
                title
                logos {
                  id
                  title
                  file {
                    fileName
                    url
                  }
                }
              }
              allContentfulFormGetStarted(filter: {node_locale: {eq: "en-US"}}) {
                edges {
                  node {
                    id
                    logo {
                      description
                      file {
                        url
                      }
                    }
                    title
                    subtitle {
                      json
                    }
                    labelFirstName
                    labelLastName
                    labelEmail
                    labelNumber
                    companyWebsiteLink
                    linkedinProfileLink
                    ctaText
                    ctaLink
                    ctaBack
                    logo {
                      id
                      description
                      file {
                        url
                      }
                    }
                  }
                }
              }
              contentfulFormSecondStep {
                id
                title
                labelTarget
                labelObjective
                ctaText
                ctaLink
                ctaSkipText
                ctaSkipLink
                quickTipTitle
                quickTipDescription {
                  json
                }
                subtitle {
                  json
                }
              }
              contentfulFormThirdStep {
                id
                title
                subtitle {
                  json
                }
                labelVersion
                labelWebsite
                labelCrm
                ctaText
                ctaLink
                ctaSkipText
                ctaSkipLink
                quickTipTitle
                quickTipDescription {
                  json
                }
              }
            contentfulFormFourthStep {
                title
                subtitle
                ctaText
                ctaLink
                quickTipTitle
                backHomepage
                backHomepageLink
                quickTipDescription{
                    json
                }
                boxOneText {
                json
                }
                boxTwoText {
                json
            }
        }
            }
          `} render={this.renderStep.bind(this)()} />
            </LayoutForm>
        )
    }
}
